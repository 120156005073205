import { useEffect } from "react";

export const CTASection = () => {

    useEffect(() => {
        const sections = document.querySelectorAll('.section');
        
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                }
            });
        }, {
            threshold: 0.2, // Trigger when 50% of the section is visible
        });
        
        // Observe each section
        sections.forEach(section => observer.observe(section));

        return () => {
            // Clean up observer on unmount
            observer.disconnect();
        };
    }, []);
    
    return(
        <>
            <section className="section py-20 bg-[#1B1B1F] flex items-center justify-between">
                <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center">
                    {/* Left content */}
                    <div className="text-center md:text-left md:w-1/2 mb-8 md:mb-0">
                        <h2 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 mb-4">
                            Ready to Bring Your Idea to Life?
                        </h2>
                        <p className="text-xl text-white mb-8">
                            Take the first step toward transforming your vision into reality. Let’s work together to make your idea flourish with creativity and precision.
                        </p>
                        <a
                            href="mailto:hello@tokyodigital.com.au"
                            className="text-white bg-[#FF3366] text-lg px-8 py-3 rounded-full hover:bg-[#E62950] transition duration-300"
                        >
                            Get In Touch
                        </a>
                    </div>

                    {/* Right image */}
                    <div className="md:w-1/2">
                        <img
                            src={require('../../assets/images/cta_image.png')}
                            alt="Call to Action"
                            className="w-full object-cover rounded-lg shadow-lg"
                        />
                    </div>
                </div>
            </section>
        </>
    )
}