import React from "react";
import { CTASection, Footer, Navbar } from "../../Components";
import caseStudies from "./clients.json"; 

const CaseStudiesScreen = () => {
  return (
    <>
      <div className="font-inter antialiased tracking-tight bg-dark bg-gray-900">
        <div className="flex flex-col min-h-screen overflow-hidden">   
          {/* Navbar */}
          <Navbar isLight/>
          
          {/* Adjust the section to have a proper margin-top */}
          <section className="py-20 bg-gray-900 text-white relative bg-cover bg-center bg-no-repeat mt-20 flex items-center justify-center">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {/* Section Title */}
              <div className="text-center mb-12">
                <h2 className="text-3xl md:text-4xl font-bold">Our Valued Clients</h2>
                <p className="text-gray-400 mt-4">
                  Explore the impact we've made by helping businesses transform their ideas into innovative digital solutions. From startups to established companies, we've been proud to support their growth and success across various industries.
                </p>
              </div>


              {/* Case Studies Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                {caseStudies.map((client) => (
                  <div
                    key={client.id}
                    className="group bg-gray-800 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105"
                  >
                    {/* Dynamically load the image */}
                    <img
                      src={client.image ? require(`../../assets/images/case_studies/${client.image}`) : '/path/to/default-image.jpg'}
                      alt={client.name}
                      className="w-full h-48 object-cover"
                    />
                    <div className="p-6">
                      <h3 className="text-2xl font-semibold mb-2">{client.name}</h3>
                      <p className="text-gray-400 mb-4">{client.description}</p>
                      <div className="flex flex-wrap gap-2">
                        {client.tools.map((tool, index) => (
                          <span
                            key={index}
                            className="bg-[#FF3366] text-white text-sm px-3 py-1 rounded-full"
                          >
                            {tool}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* CTA*/}
          <CTASection />

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CaseStudiesScreen;
