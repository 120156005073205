import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export const Navbar = ({ isLight = false }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* Default Navbar */}
      <nav
        className={`top-0 left-0 w-full z-50 transition-all duration-300 ${
          isScrolled ? 'hidden fixed' : 'absolute bg-transparent'
        } ${isLight ? 'bg-gray-800' : ''}`}
      >
        <div className="max-w-7xl mx-auto px-6 flex items-center justify-between h-20">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/">
              <img
                src={  require(`../../assets/images/${isLight || isScrolled ? 'logo_white.png' : 'logo.png'}`) }
                alt="Logo"
                className="w-[200px] h-auto"
              />
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-8">
            <a
              href="/"
              className={`relative text-lg ${
                isLight || isScrolled ? 'text-white' : 'text-black'
              } hover:text-[#FF3366] transition duration-300`}
            >
              Home
              {/* Animated Line */}
              <span
                className={`absolute left-0 bottom-0 w-0 h-[2px] bg-black transition-all duration-300 hover:w-full`}
              ></span>
            </a>
            <a
              href="/#services"
              className={`text-lg ${
                isLight || isScrolled ? 'text-white' : 'text-black'
              } hover:text-[#FF3366] transition duration-300`}
            >
              Services
            </a>
            <a
              href="/#why-us"
              className={`text-lg ${
                isLight || isScrolled ? 'text-white' : 'text-black'
              } hover:text-[#FF3366] transition duration-300`}
            >
              Why Choose Us?
            </a>
            <Link
              to="/case-studies"
              className={`text-lg ${
                isLight || isScrolled ? 'text-white' : 'text-black'
              } hover:text-[#FF3366] transition duration-300`}
            >
              Case Studies
            </Link>
            <a
              href="mailto:hello@tokyodigital.com.au"
              className="btn bg-[#FF3366] text-white px-5 py-2 rounded-full hover:bg-[#E62950] transition duration-300"
            >
              Get in Touch
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`focus:outline-none ${
                !isOpen ? 'text-[#FF3366]' : 'text-gray-300'
              }`}
            >
              {!isOpen ? (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
              </svg>
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* Scrolled Navbar */}
      <nav
        className={`fixed top-0 left-0 w-full z-50 transition-all duration-500 ${
          isScrolled ? 'bg-gray-900 bg-opacity-90 shadow-lg opacity-100 z-10' : 'opacity-0 -z-10'
        }`}
      >
        <div className="max-w-7xl mx-auto px-6 flex items-center justify-between h-20">
          <div className="flex items-center">
            <Link to="/">
              <img
                src={require('../../assets/images/logo_white.png')}
                alt="Logo"
                className="w-[200px] h-auto"
              />
            </Link>
          </div>

          <div className="hidden md:flex space-x-8">
            <a
              href="/"
              className="text-lg text-white hover:text-[#FF3366] transition duration-300"
            >
              Home
            </a>
            <a
              href="/#services"
              className="text-lg text-white hover:text-[#FF3366] transition duration-300"
            >
              Services
            </a>
            <a
              href="/#why-us"
              className="text-lg text-white hover:text-[#FF3366] transition duration-300"
            >
              Why Choose Us?
            </a>
            <Link
              to="/case-studies"
              className="text-lg text-white hover:text-[#FF3366] transition duration-300"
            >
              Case Studies
            </Link>
            <a
              href="mailto:hello@tokyodigital.com.au"
              className="btn bg-[#FF3366] text-white px-5 py-2 rounded-full hover:bg-[#E62950] transition duration-300"
            >
              Get in Touch
            </a>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`focus:outline-none ${
                !isOpen ? 'text-[#FF3366]' : 'text-gray-300'
              }`}
            >
              {!isOpen && (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 left-0 w-full h-screen bg-[#1B1B1F] text-white transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out z-50`} // Ensure mobile menu has high z-index
      >
        <div className="flex absolute right-2 justify-end px-4 pt-4">
          <button
            onClick={() => setIsOpen(false)} // Ensure this sets isOpen to false
            className="text-white focus:outline-none"
          >
            <svg
              className="h-8 w-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col items-center justify-center h-full space-y-6">
          <a
            href="/"
            className="text-2xl hover:text-[#FF3366] transition duration-300"
            onClick={() => setIsOpen(false)}
          >
            Home
          </a>
          <a
            href="#services"
            className="text-2xl hover:text-[#FF3366] transition duration-300"
            onClick={() => setIsOpen(false)}
          >
            Services
          </a>
          <a
            href="#about"
            className="text-2xl hover:text-[#FF3366] transition duration-300"
            onClick={() => setIsOpen(false)}
          >
            What We Do
          </a>
          <Link
            to="/case-studies"
            className="text-2xl hover:text-[#FF3366] transition duration-300"
            onClick={() => setIsOpen(false)}
          >
            Case Studies
          </Link>
          <a
            href="mailto:hello@tokyodigital.com.au"
            className="text-2xl bg-[#FF3366] text-white px-8 py-3 rounded-full hover:bg-[#E62950] transition duration-300"
            onClick={() => setIsOpen(false)}
          >
            Get in Touch
          </a>
        </div>
        
      </div>
      
    </>
  );
};
