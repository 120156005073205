import { FaEnvelope, FaFacebook, FaHeart, FaMapPin, FaPhone } from 'react-icons/fa';

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gray-800 text-white py-6">
            {/* Social Media Follow Section */}
            <div className="flex justify-center items-center mb-10">
                <div className="text-center">
                <h3 className="text-white text-xl font-semibold">Follow us on:</h3>
                <div className="flex justify-center mt-4 space-x-6">
                    <a href="https://www.facebook.com/tokyodigitalAU" target="_blank" className="text-white hover:text-gray-300" rel="noreferrer">
                        <FaFacebook size={30} />
                    </a>
                </div>
                </div>
            </div>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <img
                    src={require('../../assets/images/logo_white.png')}
                    alt="Logo"
                    className="w-[200px] h-auto mb-4"
                />
                
                {/* Top area: Blocks */}
                <div className="grid grid-cols-1 ml-5 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-20 mb-8 md:mb-12">

                    {/* Contact Us Block */}
                    <div className="text-sm flex flex-col items-start md:items-start">
                        <h6 className="font-medium uppercase mb-2">Contact Us</h6>
                        <ul className="space-y-1">
                            <li>
                                <a
                                    className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                    href="tel:0459353043"
                                >
                                    <FaPhone className="mr-2" /> 0459353043
                                </a>
                            </li>
                            <li>
                                <a
                                    className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                    href="mailto:hello@tokyodigital.com.au"
                                >
                                    <FaEnvelope className="mr-2" /> hello@tokyodigital.com.au
                                </a>
                            </li>
                            <li>
                                <a href="/" className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out">
                                    <FaMapPin className="mr-2" /> Perth, Western Australia, 6000
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Site Links */}
                    <div className="text-sm flex flex-col items-start md:items-start">
                        <h6 className="font-medium uppercase mb-2">Site</h6>
                        <ul className="space-y-1">
                            <li>
                                <a className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/">Home</a>
                            </li>
                            <li>
                                <a className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/#services">Services</a>
                            </li>
                            <li>
                                <a className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/#why-us">Why Choose Us? </a>
                            </li>
                            <li>
                                <a className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/case-studies">Case Studies</a>
                            </li>
                            <li>
                                <a className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="mailto:hello@tokyodigital.com.au">Get in Touch</a>
                            </li>
                        </ul>
                    </div>

                    {/* Skills Section */}
                    <div className="text-sm flex flex-col items-start md:items-start">
                        <h6 className="font-medium uppercase mb-2">Skills</h6>
                        <ul className="space-y-1">
                            <li>
                                <a className="text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/#php">PHP</a>
                            </li>
                            <li>
                                <a className="text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/#react-native">React Native</a>
                            </li>
                            <li>
                                <a className="text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/#react">React</a>
                            </li>
                            <li>
                                <a className="text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/#vue">Vue</a>
                            </li>
                        </ul>
                    </div>

                    {/* Services Section */}
                    <div className="text-sm flex flex-col items-start md:items-start">
                        <h6 className="font-medium uppercase mb-2">Services</h6>
                        <ul className="space-y-1">
                            <li>
                                <a className="text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/#ux-ui-design">UX & UI Design</a>
                            </li>
                            <li>
                                <a className="text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/#web-development">Web Development</a>
                            </li>
                            <li>
                                <a className="text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" href="/#mobile-development">Mobile Development</a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Footer Bottom Section */}
                <div className="flex flex-col md:flex-row justify-between items-center text-sm text-gray-400 mt-6">
                    <p className="mb-2 md:mb-0">&copy; {currentYear} Tokyo Digital. All rights reserved.</p>
                    <p className="flex items-center">
                        Made with <FaHeart className="text-red-500 mx-1 text-xl" /> in Perth, Western Australia
                    </p>
                </div>
            </div>
        </footer>
    );
};
