import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './Screens/HomeScreen/HomeScreen';
import NotFoundScreen from './Screens/NotFoundScreen/NotFoundScreen';
import CaseStudiesScreen from './Screens/CaseStudiesScreen/CaseStudiesScreen';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/case-studies" element={<CaseStudiesScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
