import React from "react";
import { CTASection, Footer, Navbar, } from "../../Components";
import servicesJson from './services.json';
import caseStudies from '../CaseStudiesScreen/clients.json';
import { useEffect } from "react";

const HomeScreen = () => {

    useEffect(() => {
        const sections = document.querySelectorAll('.section');
        
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                }
            });
        }, {
            threshold: 0.2, // Trigger when 50% of the section is visible
        });
        
        // Observe each section
        sections.forEach(section => observer.observe(section));

        return () => {
            // Clean up observer on unmount
            observer.disconnect();
        };
    }, []);

    return (
        <>
            <div className="font-inter antialiased tracking-tight bg-gray-800">
                {/* Navbar */}
                <div className="flex flex-col min-h-screen overflow-hidden">
                    <Navbar />
                    {/* Hero Section */}
                    <section
                        className="relative bg-white bg-cover bg-center bg-no-repeat h-screen flex items-center justify-center"
                        style={{ backgroundImage: `url(${require("../../assets/images/hero_bg.png")})` }}
                    >
                        {/* Overlay */}
                        <div className="absolute inset-0"></div>
                        <div className="relative z-10 flex flex-col md:flex-row items-center max-w-7xl mx-auto px-6">
                            <div className="text-gray-800 md:w-1/2 text-center md:text-left space-y-6">
                                <h1 className="text-5xl md:text-6xl font-extrabold leading-tight">
                                    Building for Tomorrow
                                </h1>
                                <p className="text-lg md:text-xl text-gray-800">
                                We design and develop websites, mobile apps, and software solutions that empower businesses to grow, engage their audiences, and improve everyday interactions.
                                </p>
                                <a
                                    href="mailto:hello@tokyodigital.com.au"
                                    className="inline-block bg-[#FF3366] hover:bg-[#E62950] text-white text-lg font-semibold px-6 py-3 rounded-full transition duration-300 hover:scale-105"
                                >
                                    Get in Touch
                                </a>
                            </div>

                            {/* Image */}
                            <div className="section md:w-1/2 mt-8 md:mt-0 flex justify-center">
                                <div className="w-full max-w-lg md:max-w-xl">
                                <img
                                    src={require('../../assets/images/hero-image.png')} // Use the variable for the image
                                    alt="Hero"
                                    className="w-full transform hover:scale-125 transition duration-500"
                                />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* what we do */}
                    <section id="services" className="section py-20 bg-gray-900 text-white">
                        <div className="max-w-7xl mx-auto px-6 text-center">
                            <h1 className="text-4xl font-extrabold mb-8 tracking-wide text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                What we do...
                            </h1>
                            <h2 className="text-4xl font-extrabold ">
                                Transforming Ideas into Digital Experiences
                            </h2>
                            <p className="text-lg mb-16 max-w-2xl mx-auto">
                                Here is a curated selection of our cutting-edge services that bring your vision to life in the digital world.
                            </p>
                            
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
                                {servicesJson.map((service, index) => (
                                    <div 
                                        key={index} 
                                        className="bg-gray-800 p-8 rounded-lg shadow-lg hover:bg-[#FF3366] transition duration-300 hover:scale-105"
                                    >
                                        <div className="flex justify-center mb-6">
                                            <img 
                                                src={require(`../../assets/images/services_images/${service.image}.png`)} 
                                                alt={service.title} 
                                                className="w-24 h-24 rounded-full mb-6"
                                            />
                                        </div>
                                        <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                                        <p className="text-lg">{service.description}</p>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </section>

                    {/* case studies */}
                    <section id="case-studies" className="section py-20 bg-[#1B1B1F] text-white">
                        <div className="container mx-auto px-6 ">
                            <div className="text-center">
                                <h1 className="text-4xl font-extrabold mb-8 tracking-wide text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                    Case Studies
                                </h1>
                                <p className="text-lg mb-16 max-w-2xl mx-auto">
                                    Here is a collection of some of our most impactful case studies and the clients we've had the privilege to work with. These projects showcase our ability to transform ideas into innovative digital solutions across various industries.
                                </p>
                            </div>
                            
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                                {caseStudies.slice(0, 2).map((client) => (
                                    <div
                                        key={client.id}
                                        className="group bg-gray-800 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105"
                                    >
                                        {/* Dynamically load the image */}
                                        <img
                                            src={client.image ? require(`../../assets/images/case_studies/${client.image}`) : '/path/to/default-image.jpg'}
                                            alt={client.name}
                                            className="w-full h-48 object-cover"
                                        />
                                        <div className="p-6">
                                            <h3 className="text-2xl font-semibold mb-2">{client.name}</h3>
                                            <p className="text-gray-400 mb-4">{client.description}</p>
                                            <div className="flex flex-wrap gap-2">
                                                {client.tools.map((tool, index) => (
                                                <span
                                                    key={index}
                                                    className="bg-[#FF3366] text-white text-sm px-3 py-1 rounded-full"
                                                >
                                                    {tool}
                                                </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Show More Button */}
                            <div className="text-center mt-8">
                                <a
                                    href="/case-studies"
                                    className="bg-[#FF3366] text-white px-8 py-3 rounded-full text-xl hover:bg-[#E62950] transition duration-300"
                                >
                                    See More
                                </a>
                            </div>
                        </div>
                    </section>

                    <div className="bg-[#1B1B1F] w-full flex flex-row justify-between items-center  ">
                        <div className="relative left-0 w-48 h-48 md:w-96 md:h-96 ">
                            <img
                                src={require('../../assets/images/rocket.png')}
                                alt="rocket"
                                className="w-full h-full object-cover rounded-lg"
                            />
                        </div>
                    </div>

                    {/* why us  */}
                    <section id="why-us" className="section py-20 bg-[#1B1B1F] relative">
                        <div className="max-w-7xl mx-auto text-center">
                            <h1 className="text-4xl font-extrabold mb-8 tracking-wide text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                                Why Choose Us?
                            </h1>
                            <p className="text-xl text-white mb-16">
                                At Tokyo Digital, we don’t just build websites and software; we create tailored solutions that elevate your brand and keeps you ahead of the competition. Here's why partnering with us can make a difference for your business:
                            </p>
                            <div className="flex flex-wrap justify-between space-y-4 md:space-y-0">
                                {/* Card 1: Build Brand Awareness */}
                                <div className="w-full md:w-1/3 bg-[#1B1B1F] p-6 rounded-lg shadow-xl text-center transform transition-all hover:scale-105">
                                    <h3 className="text-xl font-semibold text-[#FF3366]">Build Brand Awareness</h3>
                                    <p className="text-white mt-4">
                                    Stand out with a custom website or software that truly reflects your brand. A unique, well-designed platform can amplify your brand identity and create lasting impressions.
                                    </p>
                                </div>

                                {/* Card 2: Own Your Platform */}
                                <div className="w-full md:w-1/3 bg-[#1B1B1F] p-6 rounded-lg shadow-xl text-center transform transition-all hover:scale-105">
                                    <h3 className="text-xl font-semibold text-[#FF3366]">Own Your Platform</h3>
                                    <p className="text-white mt-4">
                                    Own a platform tailored to your needs—unique, flexible, and fully yours. No more one-size-fits-all solutions. We build scalable platforms that give you full control and flexibility.
                                    </p>
                                </div>

                                {/* Card 3: Stay Ahead of the Competition */}
                                <div className="w-full md:w-1/3 bg-[#1B1B1F] p-6 rounded-lg shadow-xl text-center transform transition-all hover:scale-105">
                                    <h3 className="text-xl font-semibold text-[#FF3366]">Stay Ahead of the Competition</h3>
                                    <p className="text-white mt-4">
                                        Bespoke solutions keep you competitive and ready for the future. With custom technology, your business stays agile, adapting quickly to new challenges and opportunities in the market.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* CTA*/}
                    <CTASection />

                    <Footer />
                </div>
            </div>
        </>
    );
};

export default HomeScreen