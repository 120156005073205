import { Footer } from "../../Components";

const NotFoundScreen = () => {
  return (
    <div className="font-inter antialiased bg-[#0d0d0d] text-gray-300 tracking-tight">
      {/* 404 Section */}
      <section className="relative h-screen flex items-center justify-center bg-[#0d0d0d] overflow-hidden">
        {/* Background Grid */}
        <div className="absolute inset-0 bg-grid-gray-600 opacity-10 pointer-events-none"></div>

        {/* Circular Pulse Around 404 */}
        <div className="relative flex items-center justify-center">
          {/* Outer Glow Circle */}
          <div className="absolute w-[200px] h-[200px] sm:w-[300px] sm:h-[300px] rounded-full bg-[#00FFFF] opacity-20 blur-3xl animate-pulse"></div>

          {/* Inner Circle Animation */}
          <div className="absolute w-[240px] h-[240px] sm:w-[300px] sm:h-[300px] rounded-full border-4 border-[#00FFFF] opacity-50 animate-ping"></div>

          {/* 404 Text */}
          <h1 className="relative text-7xl sm:text-9xl font-bold text-[#00FFFF] tracking-widest z-10">
            404
          </h1>
        </div>

        {/* Content */}
        <div className="absolute bottom-32 text-center max-w-2xl mx-auto px-6">
          <h2 className="text-2xl sm:text-4xl font-semibold text-gray-100 mb-4">
            Houston, we have a problem... 🚀
          </h2>
          <p className="text-lg sm:text-xl mb-6 text-gray-400">
            It seems like this page drifted into the void. Let’s navigate you back to home base.
          </p>

          {/* Back to Home Button */}
          <a
            href="/"
            className="inline-flex items-center justify-center px-8 py-3 text-lg font-medium text-black bg-[#00FFFF] hover:bg-[#00e0e0] rounded-full shadow-lg transition-all duration-300 transform hover:scale-105"
          >
            <span>Back to Home</span>
            <svg className="w-5 h-5 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path className="fill-current" d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z" />
            </svg>
          </a>
        </div>

        {/* Subtle Blurred Background Glow */}
        <div className="absolute top-10 right-20 w-32 h-32 bg-purple-500 opacity-20 rounded-full blur-3xl"></div>
        <div className="absolute bottom-10 left-20 w-24 h-24 bg-[#00FFFF] opacity-20 rounded-full blur-3xl"></div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default NotFoundScreen;
